import styled from "styled-components";

import { ReactComponent as Arrow } from "assets/icons/singleArrow.svg";
import COLORS from "components/atoms/colors";

interface Props {
  total: number;
  page: number;
  setPage: (e: number) => void;
}

const Pagination = ({ total, page, setPage }: Props) => {
  const pageLimit = 4;
  const startPage =
    parseInt((page - 1) / (pageLimit + 1) + "") * (pageLimit + 1) + 1;
  const numPages = Math.ceil(total / 20);
  const endPage =
    startPage + pageLimit > numPages ? numPages : startPage + pageLimit;
  const pageArray = [];
  for (let i = startPage; i <= endPage; i++) {
    pageArray.push(i);
  }
  const handlePrev = () => setPage(page - 1);
  const handleNext = () => setPage(page + 1);

  return (
    <Container>
      <BtnArrow onClick={handlePrev} disabled={page === 1}>
        <Arrow />
      </BtnArrow>
      <ButtonWrap>
        {pageArray.map((i) => (
          <Button key={i} onClick={() => setPage(i)} current={page === i}>
            {i}
          </Button>
        ))}
      </ButtonWrap>
      <BtnArrow right onClick={handleNext} disabled={page === numPages}>
        <Arrow />
      </BtnArrow>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 350px;
  margin: 40px auto 0;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-around;
  width: 200px;
  margin: 0 auto;
`;

const Button = styled.button<{ current?: boolean }>`
  background-color: #fff;
  color: ${({ current }) => current && COLORS.PINK};
`;

const BtnArrow = styled.button<{ right?: boolean }>`
  width: 22px;
  height: 22px;
  background: none;
  &[disabled] {
    display: none;
  }
  svg {
    ${({ right }) => right && "transform: rotate(180deg)"}
  }
`;

export default Pagination;
