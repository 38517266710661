import styled from "styled-components";

import COLORS from "components/atoms/colors";

export const Title = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  margin: 0;
  font-size: 19px;
  font-weight: 700;
  position: relative;
  svg {
    position: absolute;
    left: 20px;
    top: 39px;
  }
`;

export const SubTitle = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  margin: 0 0 30px 0;
  span {
    margin-left: 5px;
    color: ${COLORS.PINK};
  }
`;

export const Input = styled.input`
  border: 1px solid ${COLORS.GRAY_BORDER};
  padding: 12px 10px;
`;

export const Btn = styled.button`
  color: #fff;
  background-color: #fa5f83;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  min-width: 50px;
`;

export const Row = styled.section`
  display: flex;
  border: 1px solid ${COLORS.GRAY_BORDER};
  font-size: 14px;
  & + & {
    border-top: none;
  }
`;