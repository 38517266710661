import { Link, Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";

import { LINKS } from "datas/sideBar";
import COLORS from "components/atoms/colors";
import Top from "components/organisms/SideBarTop";
import Bottom from "components/organisms/SideBarBottom";

const SideBar = () => {
  const { pathname } = useLocation();
  const current_path = pathname.split("/")[1];

  return (
    <>
      <Container>
        <Top />
        <Center>
          {LINKS.map(({ id, link, path, title }) => (
            <Link to={link} key={id}>
              <MainItem isActive={current_path === path}>
                <ItemTitle>{title}</ItemTitle>
              </MainItem>
            </Link>
          ))}
        </Center>
        <Bottom />
      </Container>
      <Main>
        <Outlet />
      </Main>
    </>
  );
};

const Container = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  flex-shrink: 0;
  width: 200px;
  height: 100vh;
  overflow: auto;
  display: flex;
  background-color: ${COLORS.DARK_GRAY};
  color: ${COLORS.GRAY};
  flex-direction: column;
  a {
    text-decoration: none;
  }
`;

const Center = styled.div`
  flex: 1;
`;

const MainItem = styled.div<{ isActive: any }>`
  padding: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.GRAY};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.DARKER_GRAY};
  }
  background-color: ${({ isActive }) => isActive && COLORS.DARKER_GRAY};
`;

const Main = styled.main`
  margin-left: 200px;
`;

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 7px;
  }
`;

export default SideBar;
