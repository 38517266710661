import usePatchProductIsShow from "apis/usePatchProductIsShow";
import usePatchProductStatus from "apis/usePatchProductStatus";
import { UserInvests } from "types/invest";
import { IS_SHOW, STATUS } from "datas/product";
import { Table, TR, TH, TD } from "components/atoms/table";
import CustomLink from "components/molecules/CustomLink";

const ProductsTable = ({ products }: { products: UserInvests[] }) => {
  const isShowMutation = usePatchProductIsShow();
  const StatusMutation = usePatchProductStatus();

  return (
    <Table>
      <thead>
        <TR>
          <TH>코인명</TH>
          <TH>상품명</TH>
          <TH>코드값</TH>
          <TH>예치기간</TH>
          <TH>연 수익률(+추천인)</TH>
          <TH>모집일</TH>
          <TH>모집금액</TH>
          <TH>공개여부</TH>
          <TH>상태</TH>
          <TH>입금방식</TH>
          <TH>운영만기일</TH>
          <TH>상세</TH>
        </TR>
      </thead>
      <tbody>
        {products?.map(
          ({
            apy,
            code,
            collected_amount,
            deposit_end_at,
            deposit_start_at,
            fund_end_at,
            fund_start_at,
            id,
            is_need_bank,
            is_show,
            max_amount,
            name,
            name_en,
            recommend_bonus_apy,
            status,
          }) => (
            <TR key={id}>
              <TD>{name_en}</TD>
              <TD>{name}</TD>
              <TD>{code}</TD>
              <TD>
                {deposit_start_at.split("T")[0]}
                <br />~{deposit_end_at.split("T")[0]}
              </TD>
              <TD>
                {apy}%{!!recommend_bonus_apy && `(+${recommend_bonus_apy}%)`}
              </TD>
              <TD>
                {fund_start_at.split("T")[0]}
                <br />~{fund_end_at.split("T")[0]}
              </TD>
              <TD>
                {collected_amount.toLocaleString()}
                <br />/{max_amount.toLocaleString()}
              </TD>
              <TD>
                <select
                  onChange={(e) =>
                    isShowMutation.mutate({ id, is_show: +e.target.value })
                  }
                  value={is_show}
                >
                  {IS_SHOW.map(({ id, title }) => (
                    <option value={id} key={id}>
                      {title}
                    </option>
                  ))}
                </select>
              </TD>
              <TD>
                <select
                  onChange={(e) =>
                    StatusMutation.mutate({ id, status: e.target.value })
                  }
                  value={status}
                >
                  {STATUS.map((status) => (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </TD>
              <TD>{is_need_bank === 1 ? "계좌" : "지갑"}</TD>
              <TD>{deposit_end_at.split("T")[0]}</TD>
              <TD>
                <CustomLink link={`/product/${id}`} />
              </TD>
            </TR>
          )
        )}
      </tbody>
    </Table>
  );
};

export default ProductsTable;
