import { useMutation, useQueryClient } from "react-query";

import { api } from "apis";

interface Props {
  id: number;
  status: string;
}

const fetcher = async ({ id, status }: Props) => {
  const result = await api.patch(`/admin/products/${id}/status`, { status });
  return result.data;
};

const usePatchProductStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getInvests"] });
    },
  });
};

export default usePatchProductStatus;
