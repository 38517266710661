import styled from "styled-components";

import COLORS from "components/atoms/colors";

export const PageLayout = styled.div`
  background-color: #fff;
`;

export const PageContainer = styled.div`
  border: 1px solid ${COLORS.GRAY_BORDER};
  background: ${COLORS.GRAY_BACKGROUND};
  padding: 25px;
  min-height: 100vh;
`;

export const ContentBox = styled.div`
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  overflow-x: scroll;
`;
