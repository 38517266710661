import { api } from "apis/index";

export const getAmountUsers = async () => {
  const response = await api.get(`/admin/users`);
  return response.data;
};
export const getAmountUsersWeek = async () => {
  const response = await api.get(`/admin/users/week`);
  return response.data;
};
export const getAmountKRW = async () => {
  const response = await api.get(`/admin/amount/KRW`);
  return response.data;
};
export const getAmountBTC = async () => {
  const response = await api.get(`/admin/amount/BTC`);
  return response.data;
};
export const getAmountMonthKRW = async () => {
  const response = await api.get(`/admin/amount/monthKRW`);
  return response.data;
};
export const getAmountMonthBTC = async () => {
  const response = await api.get(`/admin/amount/monthBTC`);
  return response.data;
};
export const getUsers = async () => {
  const response = await api.get(`/admin/users`);
  return response.data;
};
