import { useQuery } from "react-query";

import { api } from "apis";

const fetcher = async () => {
  const response = await api.get(`/admin/users/invest`);
  return response.data;
};

const useGetInvests = () => {
  const { data, isLoading } = useQuery(["getInvests"], () => fetcher());
  return { invests: data, isLoading };
};

export default useGetInvests;
