import { Title } from "components/atoms";
import { PageContainer, PageLayout } from "components/atoms/page";
import Top from "components/templates/Main/Top";
import Center from "components/templates/Main/Center";
import Bottom from "components/templates/Main/Bottom";

const Main = () => {
  return (
    <PageLayout>
      <Title>DashBoard</Title>
      <PageContainer>
        <Top />
        <Center />
        <Bottom />
      </PageContainer>
    </PageLayout>
  );
};

export default Main;
