import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { api } from "apis";
import { Invest } from "types/invest";

const fetcher = async (product: Invest) => {
  const result = await api.post(`/admin/products`, product);
  return result.data;
};

const usePostProduct = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getMemebership"] });
      alert("상품등록이 완료되었습니다.");
      navigate(-1);
    },
    onError: () => {
      alert("상품등록에 실패하였습니다.");
    },
  });
};

export default usePostProduct;
