import { useQuery } from "react-query";

import { api } from "apis";

const fetcher = async () => {
  const response = await api.get(`/admin/users/invests/application`);
  return response.data;
};

const useGetApplication = () => {
  const { data, isLoading } = useQuery(["getApplication"], () => fetcher());
  return { application: data, isLoading };
};

export default useGetApplication;
