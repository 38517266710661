import { User } from "types/invest";
import { Table, TR, TH, TD } from "components/atoms/table";
import CustomLink from "components/molecules/CustomLink";

interface Props {
  users: User[];
  page: number;
}

const MembersTable = ({ users, page }: Props) => {
  return (
    <Table>
      <thead>
        <TR>
          <TH style={{ width: "70px" }}>순서</TH>
          <TH>이름</TH>
          <TH>ID</TH>
          <TH>연락처</TH>
          <TH>생년월일</TH>
          <TH>추천인코드</TH>
          <TH>상세보기</TH>
        </TR>
      </thead>
      <tbody>
        {users?.map(
          (
            { name, email, phone_number, birthday, recommend_code, id }: User,
            i
          ) => (
            <TR key={id}>
              <TD>{i + 1 + (page - 1) * 20}</TD>
              <TD>{name}</TD>
              <TD>{email}</TD>
              <TD>{phone_number}</TD>
              <TD>{birthday?.split("T")[0]}</TD>
              <TD>{recommend_code}</TD>
              <TD>
                <CustomLink link={`/member/${id}`} />
              </TD>
            </TR>
          )
        )}
      </tbody>
    </Table>
  );
};

export default MembersTable;
