import { useSetRecoilState } from "recoil";
import styled from "styled-components";

import { LoginState } from "stores/LoginStates";
import { ReactComponent as Logout } from "assets/icons/Logout.svg";
import { Container, Title } from "components/atoms/sideBar";

const SideBarBottom = () => {
  const setIsLoggedIn = useSetRecoilState(LoginState);
  const logout = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      localStorage.removeItem("userToken");
      setIsLoggedIn(false);
      window.location.href = "/";
    }
  };

  return (
    <CustomContainer onClick={logout}>
      <Title>로그아웃</Title>
      <Logout />
    </CustomContainer>
  );
};

const CustomContainer = styled(Container)`
  cursor: pointer;
  svg {
    height: 25px;
    width: 25px;
    margin: 0;
  }
`;

export default SideBarBottom;
