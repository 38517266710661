import styled from "styled-components";

import { Invest } from "types/invest";
import { Row } from "components/atoms";
import { ContentBox } from "components/atoms/page";
import ProductInvestor from "components/templates/Products/ProductTable/ProductInvestor";

const ProductTable = ({ invest }: { invest: Invest }) => {
  if (!invest) return null;
  const {
    amount_restrict,
    apy,
    code,
    collected_amount,
    deposit_end_at,
    deposit_start_at,
    explanation,
    fund_end_at,
    fund_start_at,
    is_need_bank,
    is_show,
    max_amount,
    min_amount,
    name,
    name_en,
    recommend_bonus_apy,
    investor,
  } = invest;

  return (
    <ContentBox>
      <Row>
        <Title>코인</Title>
        <Content>{name_en}</Content>
      </Row>
      <Row>
        <Title>상품명</Title>
        <Content>{name}</Content>
      </Row>
      <Row>
        <Title>코드값</Title>
        <Content>{code}</Content>
      </Row>
      <Row>
        <Title>예치기간</Title>
        <Content>
          {deposit_start_at.split("T")[0]} ~ {deposit_end_at.split("T")[0]}
        </Content>
      </Row>
      <Row>
        <Title>연 수익률(+추천인)</Title>
        <Content>
          {apy}
          {recommend_bonus_apy === 0 ? "" : `(+${recommend_bonus_apy}%)`}
        </Content>
      </Row>
      <Row>
        <Title>모집일</Title>
        <Content>
          {fund_start_at.split("T")[0]} ~ {fund_end_at.split("T")[0]}
        </Content>
      </Row>
      <Row>
        <Title>모집금액</Title>
        <Content>
          {collected_amount.toLocaleString()} / {max_amount.toLocaleString()}
        </Content>
      </Row>
      <Row>
        <Title>최소금액</Title>
        <Content>{min_amount.toLocaleString()}</Content>
      </Row>
      <Row>
        <Title>입력금액</Title>
        <Content>{amount_restrict.toLocaleString()}</Content>
      </Row>
      <Row>
        <Title>공개여부</Title>
        <Content>{!!is_show ? "공개" : "비공개"}</Content>
      </Row>
      <Row>
        <Title>입금방식</Title>
        <Content>{!!is_need_bank ? "계좌" : "지갑"}</Content>
      </Row>
      <Row>
        <Title>운영만기일</Title>
        <Content>{deposit_end_at.split("T")[0]}</Content>
      </Row>
      <Row>
        <Title>상품설명</Title>
        <Content>{explanation}</Content>
      </Row>
      <ProductInvestor investor={investor} />
    </ContentBox>
  );
};

const Title = styled.p`
  min-width: 145px;
  border-right: 1px solid #e0e0e0;
  padding: 15px;
  background-color: #fff6f8;
`;

const Content = styled.p`
  padding: 15px;
  white-space: pre-wrap;
`;

export default ProductTable;
