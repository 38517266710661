import { useParams } from "react-router-dom";

import useGetUser from "apis/useGetUser";
import NFT from "components/templates/Members/MemberTable/NFT";
import Detail from "components/templates/Members/MemberTable/Detail";
import Invest from "components/templates/Members/MemberTable/Invest";

const MemberTable = () => {
  const { id = "" } = useParams();
  const { userInfo, isLoading } = useGetUser(+id);

  if (isLoading) return null;
  const { invest, nft } = userInfo;

  return (
    <main>
      <Detail data={userInfo} />
      <Invest invest={invest} />
      <NFT id={+id} nft={nft} />
    </main>
  );
};

export default MemberTable;
