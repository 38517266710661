import { useMutation, useQueryClient } from "react-query";

import { api } from "apis";

interface Props {
  userId: number;
  walletAddress: string;
}

const fetcher = async (wallet: Props) => {
  const result = await api.patch("/admin/users/walletAddress", wallet);
  return result.data;
};

const usePatchWalletAddress = () => {
  const queryClient = useQueryClient();
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getUser"] });
      alert("지갑등록에 성공하였습니다.");
    },
    onError: () => {
      alert("지갑등록에 실패하였습니다.");
    },
  });
};

export default usePatchWalletAddress;
