import { useQuery } from "react-query";

import { api } from "apis";

const fetcher = async () => {
  const response = await api.get(`/admin/membershipGrades`);
  return response.data;
};

const useGetMembership = () => {
  const { data, isLoading } = useQuery(["getMembership"], () => fetcher());
  return { membershipGrades: data, isLoading };
};

export default useGetMembership;
