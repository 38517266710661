import { Link } from "react-router-dom";

import { ReactComponent as Back } from "assets/icons/Back.svg";

const CustomLink = ({ link }: { link: string }) => {
  return (
    <Link to={link}>
      <Back />
    </Link>
  );
};

export default CustomLink;
