import styled from "styled-components";

import usePostNFT from "apis/usePostNFT";
import useGetMembership from "apis/useGetMembership";
import useInputs from "hooks/useInputs";
import { MembershipGrades } from "types/membership";
import { Btn, SubTitle, Title } from "components/atoms";
import COLORS from "components/atoms/colors";

interface Props {
  onClose: () => void;
  userId: number;
}

const ModalNFT = ({ onClose, userId }: Props) => {
  const { membershipGrades, isLoading } = useGetMembership();
  const [state, onChange] = useInputs({
    membershipGradeId: 1,
    userId,
    investId: 0,
  });

  const { mutate } = usePostNFT();
  const handleMutate = () => {
    mutate({ ...state, membershipGradeId: +state.membershipGradeId });
    onClose();
  };

  if (isLoading) return null;
  return (
    <Container>
      <Title style={{ padding: "0 0 20px 0" }}>NFT 발급하기</Title>
      <SubTitle style={{ margin: "0" }}>멤버쉽 등급</SubTitle>
      <Select
        onChange={onChange}
        value={state.membershipGradeId}
        name="membershipGradeId"
      >
        {membershipGrades.map(
          ({ membershipGradeId, grade, title }: MembershipGrades) => (
            <option key={membershipGradeId} value={membershipGradeId}>
              {title} - {grade}
            </option>
          )
        )}
      </Select>
      <Row>
        <Btn onClick={handleMutate}>추가하기</Btn>
      </Row>
    </Container>
  );
};

const Container = styled.section`
  background-color: #fff;
  padding: 0 30px 30px 30px;
`;

const Select = styled.select`
  width: 100%;
  border: 1px solid ${COLORS.GRAY_BORDER};
  padding: 10px;
  margin: 10px 0 50px;
`;

const Row = styled.div`
  width: 100%;
  text-align: right;
`;

export default ModalNFT;
