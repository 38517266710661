import styled from "styled-components";

import useGetCoins from "apis/useGetCoins";
import usePostProduct from "apis/usePostProduct";
import { IS_BANK, IS_SHOW, STATUS } from "datas/product";
import useInputs from "hooks/useInputs";
import { Btn, Row } from "components/atoms";
import COLORS from "components/atoms/colors";

const DEFAULT_VALUE = {
  coin_id: 1,
  name: "",
  duration_month: 0,
  deposit_start_at: "",
  deposit_end_at: "",
  max_amount: 0,
  min_amount: 0,
  amount_restrict: 0,
  code: "",
  apy: 0,
  is_show: 0,
  is_need_bank: 0,
  fund_start_at: "",
  fund_end_at: "",
  recommend_bonus_apy: 0,
  status: "예정",
  explanation: "",
  is_notification: 0,
};

const Form = () => {
  const { coins, isLoading } = useGetCoins();
  const [state, onChange] = useInputs(DEFAULT_VALUE);
  const { mutate } = usePostProduct();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({
      ...state,
      coin_id: +state.coin_id,
      duration_month: +state.duration_month,
      max_amount: +state.max_amount,
      min_amount: +state.min_amount,
      amount_restrict: +state.amount_restrict,
      apy: +state.apy,
      is_show: +state.is_show,
      is_need_bank: +state.is_need_bank,
      recommend_bonus_apy: +state.recommend_bonus_apy,
    });
  };

  if (isLoading) return null;
  return (
    <Container onSubmit={handleSubmit}>
      <Row>
        <Title>코인</Title>
        <Select name="coin_id" onChange={onChange}>
          {coins.map(({ id, unit }: { id: number; unit: string }) => (
            <option key={id} value={id}>
              {unit}
            </option>
          ))}
        </Select>
      </Row>
      <Row>
        <Title>상품명</Title>
        <Input name="name" onChange={onChange} />
      </Row>
      <Row>
        <Title>코드값</Title>
        <Input name="code" onChange={onChange} />
      </Row>
      <Row>
        <Title>예치기간 (월)</Title>
        <Input name="duration_month" onChange={onChange} />
      </Row>
      <Row>
        <Title>예치기간 시작일</Title>
        <Input type="date" name="deposit_start_at" onChange={onChange} small />
      </Row>
      <Row>
        <Title>예치기간 종료일</Title>
        <Input type="date" name="deposit_end_at" onChange={onChange} small />
      </Row>
      <Row>
        <Title>연 수익률</Title>
        <Input name="apy" onChange={onChange} />
      </Row>
      <Row>
        <Title>연 추천인 추가 수익률</Title>
        <Input name="recommend_bonus_apy" onChange={onChange} />
      </Row>
      <Row>
        <Title>모집 시작일</Title>
        <Input type="date" name="fund_start_at" onChange={onChange} small />
      </Row>
      <Row>
        <Title>모집 종료일</Title>
        <Input type="date" name="fund_end_at" onChange={onChange} small />
      </Row>
      <Row>
        <Title>모집금액</Title>
        <Input name="max_amount" onChange={onChange} />
      </Row>
      <Row>
        <Title>최소금액</Title>
        <Input name="min_amount" onChange={onChange} />
      </Row>
      <Row>
        <Title>입력금액</Title>
        <Input name="amount_restrict" onChange={onChange} />
      </Row>
      <Row>
        <Title>공개여부</Title>
        <Select onChange={onChange} name="is_show">
          {IS_SHOW.map(({ id, title }) => (
            <option value={id} key={id}>
              {title}
            </option>
          ))}
        </Select>
      </Row>
      <Row>
        <Title>상태</Title>
        <Select onChange={onChange} name="status">
          {STATUS.map((status) => (
            <option value={status} key={status}>
              {status}
            </option>
          ))}
        </Select>
      </Row>
      <Row>
        <Title>입금방식</Title>
        <Select onChange={onChange} name="is_need_bank">
          {IS_BANK.map(({ id, title }) => (
            <option value={id} key={id}>
              {title}
            </option>
          ))}
        </Select>
      </Row>
      <Row>
        <Title>상품설명</Title>
        <Textarea name="explanation" onChange={onChange}></Textarea>
      </Row>
      <Bottom>
        <Btn>등록하기</Btn>
      </Bottom>
    </Container>
  );
};

const Container = styled.form`
  background-color: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
`;

const Title = styled.p`
  min-width: 160px;
  border-right: 1px solid ${COLORS.GRAY_BORDER};
  padding: 15px;
  background-color: ${COLORS.PINK_BACKGROUND};
`;

const Input = styled.input<{ small?: boolean }>`
  padding: 15px;
  width: ${({ small }) => (small ? "200px" : "100%")};
`;

const Select = styled.select`
  width: 150px;
  padding: 0 10px;
`;

const Bottom = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 150px;
  border: none;
  resize: none;
  padding: 5px 15px;
`;

export default Form;
