import { Title } from "components/atoms";
import { PageContainer, PageLayout } from "components/atoms/page";
import CustomLink from "components/molecules/CustomLink";
import Form from "components/templates/Edit/Form";

const Edit = () => {
  return (
    <PageLayout>
      <Title>
        <CustomLink link={"/product"} />
        투자상품 등록
      </Title>
      <PageContainer>
        <Form />
      </PageContainer>
    </PageLayout>
  );
};

export default Edit;
