import { Link } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as Logo } from "assets/icons/Logo.svg";
import { Container, Title } from "components/atoms/sideBar";

const SideBarTop = () => {
  return (
    <Layout>
      <Link to="/">
        <Logo />
      </Link>
      <Container>
        <Title style={{ cursor: "default" }}>Admin</Title>
      </Container>
    </Layout>
  );
};

const Layout = styled.section`
  svg {
    margin: 20px;
    width: 150px;
    height: 50px;
    cursor: pointer;
  }
`;

export default SideBarTop;
