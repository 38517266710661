import { SubTitle } from "components/atoms";
import { ContentBox } from "components/atoms/page";
import StatusContents from "components/templates/Status/StatusContents";

const Bottom = () => {
  return (
    <ContentBox style={{ marginTop: "30px" }}>
      <SubTitle style={{ marginBottom: "30px" }}>투자신청목록</SubTitle>
      <StatusContents />
    </ContentBox>
  );
};

export default Bottom;
