import { useQueries } from "react-query";
import styled from "styled-components";

import { getAmountMonthKRW, getAmountMonthBTC } from "apis/dashboard";
import BarChart from "charts/BarChart";
import { SubTitle } from "components/atoms";
import COLORS from "components/atoms/colors";

const Center = () => {
  const results = useQueries([
    { queryKey: "month_krw", queryFn: getAmountMonthKRW },
    { queryKey: "month_btc", queryFn: getAmountMonthBTC },
  ]);
  const [krw, btc] = results.map(({ data }) => data);

  const DataKRW = {
    labels: krw?.map(({ month }: { month: string }) => month),
    datasets: [
      {
        backgroundColor: ["rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgb(255, 99, 132)"],
        borderWidth: 1,
        data: krw?.map(({ amount }: { amount: number }) => amount),
      },
    ],
  };

  const DataBTC = {
    labels: btc?.map(({ month }: { month: string }) => month),
    datasets: [
      {
        backgroundColor: ["rgba(153, 102, 255, 0.2)"],
        borderColor: ["rgb(153, 102, 255)"],
        borderWidth: 1,
        data: btc?.map(({ amount }: { amount: number }) => amount),
      },
    ],
  };

  return (
    <Container>
      <GraphWrap>
        <SubTitle>원화 모금 통계</SubTitle>
        <BarChart data={DataKRW} />
      </GraphWrap>
      <GraphWrap>
        <SubTitle>비트코인 모금 통계</SubTitle>
        <BarChart data={DataBTC} />
      </GraphWrap>
    </Container>
  );
};

const Container = styled.main`
  display: flex;
  gap: 20px;
`;

const GraphWrap = styled.div`
  width: 100%;
  padding: 25px;
  background: #ffffff;
  border: 1px solid ${COLORS.GRAY_BORDER};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  canvas {
    max-height: 320px;
  }
`;

export default Center;
