const GRAY = "#EEEEEE";
const DARK_GRAY = "#333333";
const DARKER_GRAY = "#222222";
const GRAY_BORDER = "#E0E0E0";
const GRAY_BACKGROUND = "#F7F7F7";
const PINK = "#FF3061";
const PINK_BUTTON = "#FA5F83";
const PINK_BACKGROUND = "#FFF6F8";

const COLORS = {
  GRAY,
  DARK_GRAY,
  DARKER_GRAY,
  GRAY_BORDER,
  GRAY_BACKGROUND,
  PINK,
  PINK_BUTTON,
  PINK_BACKGROUND,
};

export default COLORS;
