import { useQuery } from "react-query";

import { api } from "apis";

const fetcher = async (id: number) => {
  const response = await api.get(`/admin/users/${id}/info`);
  return response.data;
};

const useGetUser = (id: number) => {
  const { data, isLoading } = useQuery(["getUserInfo", id], () => fetcher(id));
  return { userInfo: data, isLoading };
};

export default useGetUser;
