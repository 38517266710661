export const IS_SHOW = [
  { id: 0, title: "비공개" },
  { id: 1, title: "공개" },
];

export const STATUS = ["예정", "모집중", "마감"];

export const IS_BANK = [
  { id: 0, title: "지갑" },
  { id: 1, title: "계좌" },
]