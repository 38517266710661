import { Title } from "components/atoms";
import { PageContainer, PageLayout, ContentBox } from "components/atoms/page";
import StatusContents from "components/templates/Status/StatusContents";

const Status = () => {
  return (
    <PageLayout>
      <Title>신청현황</Title>
      <PageContainer>
        <ContentBox>
          <StatusContents />
        </ContentBox>
      </PageContainer>
    </PageLayout>
  );
};

export default Status;
