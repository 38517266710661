import styled from "styled-components";

import useInputs from "hooks/useInputs";
import { User } from "types/user";
import { Btn, Input, Row, SubTitle } from "components/atoms";
import COLORS from "components/atoms/colors";
import usePatchWalletAddress from "apis/usePatchWalletAddress";

const Detail = ({ data }: { data: User }) => {
  const {
    id,
    name,
    email,
    phone_number,
    birthday,
    recommend_code,
    walletAddress,
  } = data;

  const [state, onChange] = useInputs({ userId: id, walletAddress: "" });
  const { mutate } = usePatchWalletAddress();

  return (
    <Section>
      <SubTitle style={{ marginBottom: "10px" }}>회원 상세</SubTitle>
      <Row>
        <Title>이름</Title>
        <Content>{name}</Content>
      </Row>
      <Row>
        <Title>ID</Title>
        <Content>{email}</Content>
      </Row>
      <Row>
        <Title>연락처</Title>
        <Content>{phone_number}</Content>
      </Row>
      <Row>
        <Title>생년월일</Title>
        <Content>{birthday?.split("T")[0]}</Content>
      </Row>
      <Row>
        <Title>추천인코드</Title>
        <Content>{recommend_code}</Content>
      </Row>
      <Row>
        <Title>연결된 지갑주소</Title>
        <CustomContent>
          <Input
            value={state.walletAddress ? state.walletAddress : walletAddress}
            name="walletAddress"
            onChange={onChange}
          />
          <Btn disabled={!state.walletAddress} onClick={() => mutate(state)}>
            {walletAddress ? "수정" : "등록"}
          </Btn>
        </CustomContent>
      </Row>
    </Section>
  );
};

const Section = styled.section`
  margin-bottom: 30px;
`;

const Title = styled.p`
  min-width: 145px;
  padding: 15px;
  border-right: 1px solid ${COLORS.GRAY_BORDER};
  background-color: ${COLORS.PINK_BACKGROUND};
`;

const Content = styled.p`
  padding: 15px;
  white-space: pre-wrap;
`;

const CustomContent = styled(Content)`
  padding: 3px 10px 3px 10px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  input {
    flex: 1;
  }
`;

export default Detail;
