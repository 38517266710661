import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import { api } from "apis/index";
import { getUserToken } from "apis/account";
import { LoginState } from "stores/LoginStates";

const useTokenCheck = () => {
  const [checked, setChecked] = useState(false);
  const setIsLoggedIn = useSetRecoilState(LoginState);
  const token = getUserToken();

  useEffect(() => {
    if (token) {
      checkToken();
    } else {
      setChecked(true);
    }
  }, []);

  const checkToken = async () => {
    try {
      const response = await api.get(`/users/info`, {
        headers: { Authorization: "Bearer " + token },
      });
      if (response && token) {
        api.defaults.headers.common["Authorization"] = "Bearer " + token;
        setIsLoggedIn(true);
      }
    } catch (error) {
      setIsLoggedIn(false);
      localStorage.clear();
    }
    setChecked(true);
  };
  return checked;
};

export default useTokenCheck;
