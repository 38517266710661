import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { LoginState } from "stores/LoginStates";

const PrivateRoute = () => {
  const isLoggedIn = useRecoilValue(LoginState);
  useEffect(() => {
    if (!isLoggedIn) {
      alert("로그인 후 이용해주세요.");
    }
  }, []);
  return isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
