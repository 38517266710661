import { useState } from "react";
import styled from "styled-components";

import usePatchInvest from "apis/usePatchInvest.ts";
import useGetApplication from "apis/useGetApplication";
import { StatusType } from "types/status";
import { OPTIONS, TABS } from "datas/status";
import { SubTitle } from "components/atoms";
import { Table, TR, TH, TD } from "components/atoms/table";
import { TabConatiner, Tab, TabLine } from "components/atoms/tab";
import Modal from "components/organisms/Modal";
import Backdrop from "components/organisms/Backdrop";

const StatusContents = () => {
  const [activeCoin, setActiveCoin] = useState("");
  const { application, isLoading } = useGetApplication();
  const { mutate } = usePatchInvest();

  if (isLoading) return null;

  const activeList = application?.filter(
    ({ name_en }: StatusType) => name_en === activeCoin || activeCoin === ""
  );

  return (
    <>
      <TabConatiner>
        {TABS.map(({ id, coin, title }) => (
          <Tab
            key={id}
            isActive={activeCoin === coin}
            onClick={() => setActiveCoin(coin)}
          >
            {title}
          </Tab>
        ))}
        <TabLine />
      </TabConatiner>
      <SubTitle style={{ marginTop: "30px" }}>
        총<span>{activeList?.length}</span>개
      </SubTitle>
      <Table>
        <thead>
          <TR>
            <TH>이름</TH>
            <TH>상품명</TH>
            <TH>금액</TH>
            <TH>입금지갑(계좌정보)</TH>
            <TH>거래ID(입금증명)</TH>
            <TH>승인상태</TH>
          </TR>
        </thead>
        <tbody>
          {activeList?.map(
            ({
              name,
              product_name,
              amount,
              wallet_address,
              account_number,
              bank,
              account_holder,
              verify_certificate,
              verify_deposit,
              typed_wallet,
              id,
            }: StatusType) => (
              <TR key={id}>
                <TD>{name}</TD>
                <TD>{product_name}</TD>
                <TD>{amount.toLocaleString()}</TD>
                <CustomTD>
                  {wallet_address ||
                    `${bank} ${account_number} ${account_holder}`}
                </CustomTD>
                <CustomTD>
                  {verify_certificate ? (
                    <Modal
                      activator={(onOpen) => (
                        <Img src={verify_certificate} onClick={onOpen} />
                      )}
                      content={(onClose) => (
                        <Backdrop img={verify_certificate} onClose={onClose} />
                      )}
                    />
                  ) : (
                    verify_deposit
                  )}
                </CustomTD>
                <TD>
                  <select
                    value={typed_wallet}
                    onChange={(e) =>
                      mutate({ invest_id: id, typed_wallet: e.target.value })
                    }
                  >
                    {OPTIONS.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </TD>
              </TR>
            )
          )}
          {activeList.length === 0 && (
            <TR>
              <TD colSpan={6}>신청목록이 없습니다</TD>
            </TR>
          )}
        </tbody>
      </Table>
    </>
  );
};

const CustomTD = styled(TD)`
  max-width: 400px;
  word-break: break-all;
  border-right: 1px solid #e0e0e0;
  padding: 0 5px;
`;

const Img = styled.img`
  max-width: 300px;
  max-height: 100px;
`;

export default StatusContents;
