import styled from "styled-components";

interface Props {
  img: string;
  onClose: () => void;
}

const Backdrop = ({ img, onClose }: Props) => {
  return (
    <Container onClick={onClose}>
      <ContentContainer>
        <Img src={img} />
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 100%;
`;

export default Backdrop;
