import { useQuery } from "react-query";

import { api } from "apis";

const fetcher = async (id: string) => {
  const response = await api.get(`/admin/users/invest/${id}`, {
    params: { id: id },
  });
  return response.data;
};

const useGetInvest = (id: string) => {
  const { data, isLoading } = useQuery(["getInvest", id], () => fetcher(id));
  return { invest: data, isLoading };
};

export default useGetInvest;
