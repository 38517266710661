import { useParams } from "react-router-dom";

import useGetInvest from "apis/useGetInvest";
import { Title } from "components/atoms";
import { PageContainer, PageLayout } from "components/atoms/page";
import CustomLink from "components/molecules/CustomLink";
import ProductTable from "components/templates/Products/ProductTable";

const Product = () => {
  const { id = "" } = useParams();
  const { invest, isLoading } = useGetInvest(id);

  if (isLoading) return null;
  return (
    <PageLayout>
      <Title>
        <CustomLink link={"/product"} />
        투자상품 상세
      </Title>
      <PageContainer>
        <ProductTable invest={invest} />
      </PageContainer>
    </PageLayout>
  );
};

export default Product;
