import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { LoginState } from "stores/LoginStates";
import PrivateRoute from "Router/PrivateRoute";

import ScrollToTop from "components/molecules/ScrollToTop";
import SideBar from "components/organisms/SideBar";
import Main from "components/pages/Main";
import Login from "components/pages/Login";
import Members from "components/pages/Members";
import Member from "components/pages/Member";
import Products from "components/pages/Products";
import Product from "components/pages/Product";
import Status from "components/pages/Status";
import Empty from "components/pages/Empty";
import Edit from "components/pages/Edit";

const Routers = () => {
  const isLoggedIn = useRecoilValue(LoginState);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {isLoggedIn ? (
          <Route path="/" element={<SideBar />}>
            <Route path="/" element={<Main />} />
          </Route>
        ) : (
          <Route path="/" element={<Login />} />
        )}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<SideBar />}>
            <Route path="/" element={<Main />} />
            <Route path="member" element={<Members />} />
            <Route path="member/:id" element={<Member />} />
            <Route path="product" element={<Products />} />
            <Route path="product/:id" element={<Product />} />
            <Route path="edit" element={<Edit />} />
            <Route path="status" element={<Status />} />
          </Route>
        </Route>
        <Route path="*" element={<Empty />} />
      </Routes>
    </Router>
  );
};

export default Routers;
