import { useQuery } from "react-query";

import { api } from "apis";

const fetcher = async (page: number) => {
  const response = await api.get(`/admin/users/info`, {
    params: { page },
  });
  return response.data;
};

const useGetUsers = (page: number) => {
  const { data, isLoading } = useQuery(["getUsersInfo", page], () =>
    fetcher(page)
  );
  return { usersInfo: data, isLoading };
};

export default useGetUsers;
