import styled from "styled-components";

import { Investor } from "types/invest";
import { SubTitle } from "components/atoms";
import { Table, TR, TH, TD } from "components/atoms/table";
import Modal from "components/organisms/Modal";
import Backdrop from "components/organisms/Backdrop";

const ProductInvestor = ({ investor }: { investor: Investor[] }) => {
  return (
    <>
      <CustomTitle>투자자목록</CustomTitle>
      <Table>
        <thead>
          <TR>
            <TH>이름</TH>
            <TH>금액</TH>
            <TH>입금정보(입금지갑)</TH>
            <TH>입금증명(거래ID)</TH>
            <TH>승인상태</TH>
          </TR>
        </thead>
        <tbody>
          {investor.map(
            (
              {
                account_holder,
                account_number,
                amount,
                bank,
                name,
                typed_wallet,
                verify_certificate,
                verify_deposit,
                wallet_address,
              },
              i: number
            ) => (
              <TR key={i}>
                <TD>{name}</TD>
                <TD>{amount.toLocaleString()}</TD>
                <Td>
                  {wallet_address ||
                    `${bank}${account_number}${account_holder}`}
                </Td>
                <Td>
                  {verify_certificate ? (
                    <Modal
                      activator={(onOpen) => (
                        <Img src={verify_certificate} onClick={onOpen} />
                      )}
                      content={(onClose) => (
                        <Backdrop img={verify_certificate} onClose={onClose} />
                      )}
                    />
                  ) : (
                    verify_deposit
                  )}
                </Td>
                <TD>{typed_wallet}</TD>
              </TR>
            )
          )}
          {investor.length === 0 && (
            <TR>
              <TD colSpan={5}>투자자가 없습니다.</TD>
            </TR>
          )}
        </tbody>
      </Table>
    </>
  );
};

const CustomTitle = styled(SubTitle)`
  margin: 30px 0 0 0;
  padding: 0;
`;

const Td = styled(TD)`
  max-width: 200px;
  word-break: break-all;
`;

const Img = styled.img`
  max-width: 300px;
  max-height: 100px;
`;

export default ProductInvestor;
