import { useMutation, useQueryClient } from "react-query";

import { api } from "apis";

interface Props {
  membershipGradeId: number;
  userId: number;
  investId: number;
}

const fetcher = async (userInfo: Props) => {
  const result = await api.post(`/admin/nft`, userInfo);
  return result.data;
};

const usePostNFT = () => {
  const queryClient = useQueryClient();
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getMemebership"] });
      alert("NFT등록이 완료되었습니다.");
    },
    onError: () => {
      alert("NFT등록에 실패하였습니다.");
    },
  });
};

export default usePostNFT;
