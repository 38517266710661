import { Title } from "components/atoms";
import { PageContainer, PageLayout, ContentBox } from "components/atoms/page";
import CustomLink from "components/molecules/CustomLink";
import MemberTable from "components/templates/Members/MemberTable";

const Member = () => {
  return (
    <PageLayout>
      <Title>
        <CustomLink link={"/member"} />
        회원 상세
      </Title>
      <PageContainer>
        <ContentBox>
          <MemberTable />
        </ContentBox>
      </PageContainer>
    </PageLayout>
  );
};

export default Member;
