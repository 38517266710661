import { Link } from "react-router-dom";
import styled from "styled-components";

import useGetInvests from "apis/useGetInvests";
import COLORS from "components/atoms/colors";
import { SubTitle, Title } from "components/atoms";
import { PageContainer, PageLayout, ContentBox } from "components/atoms/page";
import ProductsTable from "components/templates/Products/ProductsTable";

const Products = () => {
  const { invests, isLoading } = useGetInvests();

  if (isLoading) return null;
  return (
    <PageLayout>
      <Title style={{ justifyContent: "space-between" }}>
        투자상품 관리
        <CustomLink to="/edit">상품등록</CustomLink>
      </Title>
      <PageContainer>
        <ContentBox>
          <SubTitle>
            전체 투자상품<span>{invests?.length}</span> 개
          </SubTitle>
          <ProductsTable products={invests} />
        </ContentBox>
      </PageContainer>
    </PageLayout>
  );
};

const CustomLink = styled(Link)`
  font-size: 16px;
  background-color: ${COLORS.PINK_BUTTON};
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
`;

export default Products;
