import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";

import { api } from "apis";
import { login } from "apis/account";
import useInputs from "hooks/useInputs";
import { LoginState } from "stores/LoginStates";
import { Btn, Input } from "components/atoms";
import COLORS from "components/atoms/colors";

const Login = () => {
  const navigate = useNavigate();
  const setIsLoggedIn = useSetRecoilState(LoginState);
  const [state, onChange] = useInputs({ email: "", password: "" });

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = await login(state);
    if (result) {
      const token = result.data.token;
      localStorage.setItem("userToken", result.data.token);
      api.defaults.headers.common["Authorization"] = "Bearer " + token;
      setIsLoggedIn(true);
      navigate("/");
    }
  };

  return (
    <Container>
      <Form onSubmit={handleLogin}>
        <Input
          placeholder="아이디(이메일)"
          name="email"
          value={state.email}
          onChange={onChange}
        />
        <Input
          placeholder="비밀번호"
          type="password"
          name="password"
          value={state.password}
          onChange={onChange}
        />
        <Btn style={{ padding: "10px 0" }}>로그인</Btn>
      </Form>
    </Container>
  );
};

const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Form = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  border: 1px solid ${COLORS.GRAY_BORDER};
  padding: 55px 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
`;

export default Login;
