import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Btn, Title } from "components/atoms";

const Empty = () => {
  const navigate = useNavigate();

  return (
    <Main>
      <Title>정상적인 주소가 아닙니다.</Title>
      <Btn onClick={() => navigate("/")}>돌아가기</Btn>
    </Main>
  );
};

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
`;

export default Empty;
