import styled from "styled-components";

import COLORS from "components/atoms/colors";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 12px;
`;

export const TR = styled.tr`
  border: 1px solid ${COLORS.GRAY_BORDER};
`;

export const TH = styled.th`
  text-align: center;
  color: #989899;
  border-top: 1px solid ${COLORS.GRAY_BORDER};
  height: 45px;
  font-size: 14px;
  background-color: #fff6f8;
  border-right: 1px solid ${COLORS.GRAY_BORDER};
`;

export const TD = styled.td`
  font-weight: 400;
  font-size: 14px;
  height: 60px;
  text-align: ${({ left }: { left?: boolean }) => (left ? "left" : "center")};
  border-right: 1px solid ${COLORS.GRAY_BORDER};
  min-width: 100px;
  padding: 0 5px;
  svg {
    transform: rotate(180deg);
  }
`;
