import { UserInvest } from "types/user";
import { SubTitle } from "components/atoms";
import { Table, TR, TH, TD } from "components/atoms/table";

const Invest = ({ invest }: { invest: UserInvest[] }) => {
  return (
    <section style={{ marginBottom: "30px" }}>
      <SubTitle style={{ marginBottom: "10px" }}>투자상품이력</SubTitle>
      <Table>
        <thead>
          <TR>
            <TH style={{ width: "70px" }}>순서</TH>
            <TH>상품명</TH>
            <TH>금액</TH>
            <TH>수익률</TH>
            <TH>추천인이름</TH>
            <TH>수령할지갑</TH>
          </TR>
        </thead>
        <tbody>
          {invest.map(
            (
              {
                name,
                amount,
                apy,
                recommend_bonus_apy,
                recommend_code,
                wallet_address,
                bank,
                account_holder,
                account_number,
              },
              i: number
            ) => (
              <TR key={i}>
                <TD>{i + 1}</TD>
                <TD>{name}</TD>
                <TD>{amount.toLocaleString()}</TD>
                <TD>{apy + recommend_bonus_apy}%</TD>
                <TD>{recommend_code}</TD>
                <TD>
                  {wallet_address
                    ? wallet_address
                    : `${bank} ${account_number} ${account_holder}`}
                </TD>
              </TR>
            )
          )}
          {invest.length === 0 && (
            <TR>
              <TD colSpan={6}>투자내역이 없습니다.</TD>
            </TR>
          )}
        </tbody>
      </Table>
    </section>
  );
};

export default Invest;
