import { useQueries } from "react-query";
import styled from "styled-components";

import {
  getAmountUsers,
  getAmountUsersWeek,
  getAmountKRW,
  getAmountBTC,
} from "apis/dashboard";
import { MENUS } from "datas/dashboard";

const Top = () => {
  const results = useQueries([
    { queryKey: "users", queryFn: getAmountUsers },
    { queryKey: "usersWeek", queryFn: getAmountUsersWeek },
    { queryKey: "krw", queryFn: getAmountKRW },
    { queryKey: "btc", queryFn: getAmountBTC },
  ]);
  const [users, usersWeek, krw, btc] = results.map(({ data }) => data);
  const amounts = [
    users?.amount_users?.toLocaleString() + "명",
    usersWeek?.two_week_users?.toLocaleString() + "명",
    krw?.amount_KRW?.toLocaleString() + "원",
    btc?.amount_BTC?.toLocaleString() + "BTC",
  ];

  return (
    <Container>
      {MENUS.map(({ cardColor, title }, i) => (
        <Card ColorData={cardColor} key={title}>
          <Content>
            {results[i].status === "success" ? amounts[i] : "loading..."}
          </Content>
          <Title>{title}</Title>
        </Card>
      ))}
    </Container>
  );
};

const Container = styled.main`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const Card = styled.section<{ ColorData: string }>`
  width: 247px;
  padding: 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  border-left: ${({ ColorData }) => `4px solid ${ColorData}`};
`;

const Title = styled.p`
  font-size: 14px;
  color: #616161;
`;

const Content = styled.p`
  font-size: 20px;
`;

export default Top;
