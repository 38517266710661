import { useReducer, ChangeEvent } from "react";

const reducer = (state: any, action: HTMLInputElement) => {
  const { name, value } = action;
  return {
    ...state,
    [name]: value,
  };
};

const useInputs = (initialForm: any) => {
  const [state, dispatch] = useReducer(reducer, initialForm);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => dispatch(e.target);
  return [state, onChange];
};

export default useInputs;
