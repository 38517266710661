import { useMutation, useQueryClient } from "react-query";

import { api } from "apis";

interface Props {
  id: number;
  is_show: number;
}

const fetcher = async ({ id, is_show }: Props) => {
  const result = await api.patch(`/admin/products/${id}/is_show`, { is_show });
  return result.data;
};

const usePatchProductIsShow = () => {
  const queryClient = useQueryClient();
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getInvests"] });
    },
  });
};

export default usePatchProductIsShow;
