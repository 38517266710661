import { useState } from "react";

import useGetUsers from "apis/useGetUsers";
import { SubTitle, Title } from "components/atoms";
import { PageContainer, PageLayout, ContentBox } from "components/atoms/page";
import Pagination from "components/organisms/Pagination";
import MembersTable from "components/templates/Members/MembersTable";

const Members = () => {
  const [page, setPage] = useState(1);
  const { usersInfo, isLoading } = useGetUsers(page);

  if (isLoading) return null;
  const { users, amount_users } = usersInfo;

  return (
    <PageLayout>
      <Title>회원 관리</Title>
      <PageContainer>
        <ContentBox>
          <SubTitle>
            전체 사용자<span>{amount_users}</span>명
          </SubTitle>
          <MembersTable users={users} page={page} />
          <Pagination total={amount_users} page={page} setPage={setPage} />
        </ContentBox>
      </PageContainer>
    </PageLayout>
  );
};

export default Members;
