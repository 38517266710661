import styled from "styled-components";

export const Container = styled.article`
  width: 100%;
  padding: 10px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  border-bottom: 1px solid #444;
  border-top: 1px solid #444;
`;

export const Title = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
`;
