import { QueryClientProvider, QueryClient } from "react-query";

import Router from "Router/Router";
import useTokenCheck from "hooks/useTokenCheck";

function App() {
  const queryClient = new QueryClient();
  const isTokenChecked = useTokenCheck();

  if (!isTokenChecked) return null;
  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  );
}

export default App;
