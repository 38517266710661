import { AxiosError } from "axios";

import { api } from "apis/index";

export const login = async (loginData: { email: string; password: string }) => {
  try {
    const response = await api.post("/admin/logIn", loginData);
    return response;
  } catch (error) {
    const err = error as AxiosError;
    if (err.response) {
      alert("아이디 또는 비밀번호를 확인해주세요.");
    }
  }
};

export const getUserToken = (): string => {
  const token = localStorage.getItem("userToken");
  return token ? token : "";
};
