import { useMutation, useQueryClient } from "react-query";

import { api } from "apis";
import { InvestStatus } from "types/status";

const fetcher = async (patchData: InvestStatus) => {
  const result = await api.patch("/admin/users/invests", patchData);
  return result.data;
};

const usePatchInvest = () => {
  const queryClient = useQueryClient();
  return useMutation(fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getApplication"] });
    },
    onError: () => {
      alert("승인상태변경에 실패하였습니다.");
    },
  });
};

export default usePatchInvest;
