import styled from "styled-components";

import { UserNFT } from "types/user";
import { Btn, SubTitle } from "components/atoms";
import COLORS from "components/atoms/colors";
import { BackdropImg } from "components/atoms/modal";
import Modal from "components/organisms/Modal";
import ModalNFT from "components/templates/Members/MemberTable/ModalNFT";

interface Props {
  id: number;
  nft: UserNFT[];
}

const NFT = ({ nft, id }: Props) => {
  return (
    <section>
      <Title>
        <SubTitle style={{ marginBottom: "10px" }}>NFT 지급 내역</SubTitle>
        <Modal
          activator={(onOpen) => <Btn onClick={onOpen}>NFT 발급</Btn>}
          content={(onClose) => <ModalNFT onClose={onClose} userId={id} />}
        />
      </Title>
      <List>
        {nft?.map(({ metadata, tokenId }) => (
          <Card key={tokenId}>
            <Modal
              activator={(onOpen) => (
                <Image src={metadata.image} onClick={onOpen} />
              )}
              content={() => <BackdropImg src={metadata.image} />}
            />
            <Name>{metadata.name}</Name>
            <Row>
              <span>tokenID -</span> {tokenId}
            </Row>
            <Row>
              <span>membership -</span> {metadata.membership}
            </Row>
            <Row style={{ flexDirection: "column" }}>
              <span>description</span> {metadata.description}
            </Row>
          </Card>
        ))}
        {nft?.length === 0 && <p>지급내역이 없습니다.</p>}
      </List>
    </section>
  );
};

const Title = styled.section`
  display: flex;
  justify-content: space-between;
`;

const List = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  padding-top: 30px;
  font-weight: 400;
  font-size: 14px;
`;

const Card = styled.article`
  padding: 10px 0;
  border: 1px solid ${COLORS.GRAY_BORDER};
  text-align: center;
  width: 200px;
`;

const Image = styled.img`
  margin-bottom: 10px;
  cursor: pointer;
  width: 180px;
`;

const Name = styled.h4`
  background-color: ${COLORS.GRAY_BACKGROUND};
  padding: 10px 0;
  overflow-x: auto;
  margin: 0 0 5px;
`;

const Row = styled.section`
  display: flex;
  font-size: 13px;
  padding: 3px 10px;
  gap: 3px;
  text-align: left;
  span {
    font-weight: 500;
  }
`;

export default NFT;
