import styled from "styled-components";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  BarController,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  BarController
);

const BarChart = ({ data }) => {
  return (
    <Container>
      <ChartWrap>
        <Chart type="bar" data={data} width={500} height={300} />
      </ChartWrap>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
const ChartWrap = styled.div`
  min-width: 500px;
  width: 100%;
  height: 300px;
`;

export default BarChart;
